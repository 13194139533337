import { logger } from './logger'
import { signOut } from 'next-auth/react'
import posthog from 'posthog-js'

export const logOut = async (option) => {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')

    posthog.reset()

    if (option) signOut(option)
    else signOut()
}

export const getFetcher = async (url, token, extraHeaders = {}) => {
    const headers = {
        'Content-Type': 'application/json',
        'noice-platform': 'web',
        'noice-app': 'web',
        'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
        ...extraHeaders,
    }

    if (token) {
        headers['authorization'] = `Bearer ${token}`
    }

    const fullUrl = `${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`

    // console.log('Curl command for debugging:')
    // console.log(`curl -X GET '${fullUrl}' \\`)
    // Object.entries(headers).forEach(([key, value]) => {
    //     console.log(`  -H '${key}: ${value}' \\`)
    // })
    // console.log('  --compressed')

    const start = Date.now()

    const res = await fetch(fullUrl, {
        method: 'GET',
        mode: 'cors',
        headers,
    })

    // console.log('res', res)

    if (res.status === 401 && typeof window !== 'undefined') {
        try {
            const sessionRes = await fetch(
                `${process.env.NEXT_PUBLIC_AUTH_SESSION_SITE_URL}/api/auth/session`
            )
            const session = await sessionRes.json()
            const newToken = session?.token

            if (window.updateSession) window.updateSession()

            const start = Date.now()

            const newRes = await fetch(fullUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    ...headers,
                    authorization: `Bearer ${newToken}`,
                },
            })

            if (newRes.status === 401 && typeof window !== 'undefined') {
                logger({
                    url,
                    response_status: res.status,
                    responseTime: Date.now() - start,
                })

                await logOut()
            }

            const newJsonData = await newRes.json()

            logger({
                url,
                response_status: res.status,
                message: newJsonData?.error_service?.http_body || '',
                responseTime: Date.now() - start,
            })

            return newJsonData
        } catch (error) {
            console.log('Error on Logout in Auth.js', error)
            await logOut({ callbackUrl: '/' })
        }
    }

    const jsonData = await res.json()

    logger({
        url,
        response_status: res.status,
        message: jsonData?.error_service?.http_body || '',
        responseTime: Date.now() - start,
    })

    if (res.ok) {
        return jsonData
    } else {
        return {
            stauts: jsonData?.statusCode || jsonData?.status || res.status,
            message: jsonData?.message || res.statusText,
        }
    }
}

export const postFetcher = async ({ url, body, header }) => {
    const start = Date.now()

    const res = await fetch(`${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'noice-platform': 'web',
            'noice-app': 'web',
            'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
            ...header,
        },
    })
    if (res.status === 401 && typeof window !== 'undefined') {
        const sessionRes = await fetch(
            `${process.env.NEXT_PUBLIC_AUTH_SESSION_SITE_URL}/api/auth/session`
        )
        const session = await sessionRes.json()
        const newToken = session.token

        if (window.updateSession) window.updateSession()

        const start = Date.now()

        // hit again with new token
        const newRes = await fetch(
            `${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`,
            {
                method: 'POST',
                body: JSON.stringify(body),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'noice-platform': 'web',
                    'noice-app': 'web',
                    'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
                    ...header,
                    authorization: `Bearer ${newToken}`,
                },
            }
        )

        if (newRes.status === 401 && typeof window !== 'undefined') {
            logger({
                url,
                response_status: res.status,
                responseTime: Date.now() - start,
            })

            await logOut()
        }

        const newJsonData = await newRes.json()

        logger({
            url,
            response_status: res.status,
            message: newJsonData?.error_service?.http_body || '',
            responseTime: Date.now() - start,
        })

        return newJsonData
    }

    const jsonData = await res.json()

    logger({
        url,
        response_status: res.status,
        message: jsonData?.error_service?.http_body || '',
        responseTime: Date.now() - start,
    })

    return jsonData
}

export const sameOriginFetcher = (url) =>
    fetch(`${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`).then((res) =>
        res.json()
    )

export const putFetcher = async ({ url, body, header }) => {
    const start = Date.now()

    const res = await fetch(`${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'noice-platform': 'web',
            'noice-app': 'web',
            'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
            ...header,
        },
    })

    if (res.status === 401 && typeof window !== 'undefined') {
        const sessionRes = await fetch(
            `${process.env.NEXT_PUBLIC_AUTH_SESSION_SITE_URL}/api/auth/session`
        )
        const session = await sessionRes.json()
        const newToken = session.token

        if (window.updateSession) window.updateSession()

        const start = Date.now()

        // hit again with new token
        const newRes = await fetch(
            `${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`,
            {
                method: 'PUT',
                body: JSON.stringify(body),
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'noice-platform': 'web',
                    'noice-app': 'web',
                    'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
                    ...header,
                    authorization: `Bearer ${newToken}`,
                },
            }
        )

        if (newRes.status === 401 && typeof window !== 'undefined') {
            logger({
                url,
                response_status: res.status,
                responseTime: Date.now() - start,
            })

            await logOut()
        }

        const newJsonData = await newRes.json()

        logger({
            url,
            response_status: res.status,
            message: newJsonData?.error_service?.http_body || '',
            responseTime: Date.now() - start,
        })

        return newJsonData
    }

    const jsonData = await res.json()

    logger({
        url,
        response_status: res.status,
        message: jsonData?.error_service?.http_body || '',
        responseTime: Date.now() - start,
    })

    return jsonData
}

export const deleteFetcher = async ({ url, body, header }) => {
    const start = Date.now()

    const res = await fetch(`${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`, {
        method: 'DELETE',
        body: body ? JSON.stringify(body) : null,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'noice-platform': 'web',
            'noice-app': 'web',
            'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
            ...header,
        },
    })

    if (res.status === 401 && typeof window !== 'undefined') {
        const sessionRes = await fetch(
            `${process.env.NEXT_PUBLIC_AUTH_SESSION_SITE_URL}/api/auth/session`
        )
        const session = await sessionRes.json()
        const newToken = session.token

        if (window.updateSession) window.updateSession()

        const start = Date.now()

        // hit again with new token
        const newRes = await fetch(
            `${process.env.NEXT_PUBLIC_NOICE_BASE_URL}${url}`,
            {
                method: 'DELETE',
                body: body ? JSON.stringify(body) : null,
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'noice-platform': 'web',
                    'noice-app': 'web',
                    'data-version': process.env.NEXT_PUBLIC_API_DATA_VERSION,
                    ...header,
                    authorization: `Bearer ${newToken}`,
                },
            }
        )

        if (newRes.status === 401 && typeof window !== 'undefined') {
            logger({
                url,
                response_status: res.status,
                responseTime: Date.now() - start,
            })

            await logOut()
        }

        const newJsonData = await newRes.json()

        logger({
            url,
            response_status: res.status,
            message: newJsonData?.error_service?.http_body || '',
            responseTime: Date.now() - start,
        })

        return newJsonData
    }

    const jsonData = await res.json()

    logger({
        url,
        response_status: res.status,
        message: jsonData?.error_service?.http_body || '',
        responseTime: Date.now() - start,
    })

    return jsonData
}
